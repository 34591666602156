import React, { useEffect, useState } from 'react';
import './Tabs.scss';

export default function Tabs({titleOne, titleTwo, tabFillOne, tabFillTwo, tabSwitchHandler}) {

  const [oneSelected, setOneSelected] = useState(true)

  useEffect(() => {
    tabSwitchHandler()
  }, [oneSelected]); // eslint-disable-line react-hooks/exhaustive-deps


  const oneIsSelected = oneSelected ? "selected" : "not-selected";
  const twoIsSelected = oneSelected ? "not-selected" : "selected";
  const show = oneSelected ? tabFillOne : tabFillTwo;

  return (
    <div className="tabs-container">
      <div className="tab-nav">

        <div className="tab"
           role="button"
           tabIndex={0}
           onClick={() => setOneSelected(true)}
           onKeyDown={() => setOneSelected(true)}
        >
          <div className={`tab-clickable-area ${oneIsSelected}`}>{titleOne}</div>
          <div className={`selector ${oneIsSelected}`}></div>
        </div>

        <div className="tab"
          role="button"
          tabIndex={0}
          onClick={() => setOneSelected(false)}
          onKeyDown={() => setOneSelected(false)}
        >
          <div className={`tab-clickable-area ${twoIsSelected}`} >{titleTwo}</div>
          <div className={`selector ${twoIsSelected}`}></div>
        </div>

      </div>
      <div className="selected-tab">
        {show}
      </div>
    </div>
  )
}