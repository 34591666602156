import { MDXProvider } from "@mdx-js/react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import React, { useState } from 'react'
import { Helmet } from "react-helmet"
import Accordion from '../components/Accordion'
import CallOut from '../components/CallOut'
import Card from '../components/Card'
import CardContainer from '../components/CardContainer'
import Carousel from '../components/Carousel'
import DetailTemplate from '../components/DetailTemplate'
import FactBox from '../components/FactBox'
import FlipCard from '../components/FlipCard'
import FlipCardContainer from '../components/FlipCardContainer'
import HeaderWithImage from '../components/HeaderWithImage'
import HTTTYP from '../components/HTTTYP'
import LinkButton from '../components/LinkButton'
import PenisExternal from '../components/PenisExternal'
import PenisInternal from '../components/PenisInternal'
import Question from '../components/Question'
import Quiz from '../components/Quiz'
import ShowMore from '../components/ShowMore'
import Slide from '../components/Slide'
import Tabs from '../components/Tabs'
import TextCarousel from '../components/TextCarousel'
import TextSlide from '../components/TextSlide'
import './bodies.scss'


export default function BodiesWithVulva({pageContext}) {
  const { detail } = pageContext

  const [showDetail, setShowDetail] = useState(null)
  const [modalOpen, setModalOpen] = useState(true);

  const shortcodes = { Card, CardContainer, FactBox, Accordion, Carousel, 
    Slide, HeaderWithImage, ShowMore, FlipCard, TextCarousel, TextSlide, 
    LinkButton, Quiz, Question, FlipCardContainer, HTTTYP, CallOut}

  let contentBlock = null
  if (showDetail)
    contentBlock = detail.frontmatter[showDetail]

  function tabSwitchHandler() {
    setShowDetail(null)
  }

  const content = () => {
    return (
      showDetail?
        (<MDXProvider><MDXRenderer>{contentBlock}</MDXRenderer></MDXProvider>)
        : (<h2 className="start">Click a label to explore</h2>)
    )
  }

  return (
    <div className="bodies-with-vulva-container">
      <Helmet>
        <title>{detail.frontmatter.title}</title>
        <meta name="description" content={detail.frontmatter.description} />
      </Helmet>
      { modalOpen &&
        <div id="BodiesModal">
          <div className="message">
            <MDXProvider >
              <MDXRenderer>
                {detail.frontmatter.modal}
              </MDXRenderer>
            </MDXProvider>
            <button onClick={() => setModalOpen(false)} className="look"> TAKE A CLOSER LOOK</button>
          </div>
        </div>
      }
      <DetailTemplate 
        detailTitle={detail.frontmatter.detailTitle} 
        topicTitle={detail.frontmatter.topicTitle} 
        sectionTitle={detail.frontmatter.sectionTitle} 
        image={detail.frontmatter.image}
        path={detail.slug}
        shortHeader={true}
      >
        <Tabs
          titleOne="INTERNAL"
          titleTwo="EXTERNAL"
          tabFillOne={<PenisInternal clickHandler={setShowDetail} selected={showDetail}/>}
          tabFillTwo={<PenisExternal clickHandler={setShowDetail} selected={showDetail}/>}
          tabSwitchHandler={tabSwitchHandler}
          />
        <div className="detail-content">{content()}</div>
        <div className="body-crib">
          <MDXProvider components={shortcodes}>
            <MDXRenderer>
              {detail.body}
            </MDXRenderer>
          </MDXProvider>
        </div>
      </DetailTemplate>
    </div>
  )
}

export const pageQuery = graphql`
  query {
    mdx(slug: {eq: "bodies/penis-and-testicles"}) {
      body
      frontmatter {
        title
        modal
        bladder
        urethra
        seminalVesicle
        prostateGland
        vasDeferens
        epididymis
        testicle
        scrotum
        foreskin
        urethralOpening
        penis
      }
    }
  }
`