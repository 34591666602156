import React from 'react'
import './PenisExternal.scss'

const PenisExternal = ({selected, clickHandler}) => {

  const clickableImage = (kebabPart, camelPart) => {
    return (
      <button onClick={()=>clickHandler(camelPart)} >
        <img
          className={`${kebabPart} label ${!selected || selected === camelPart}`}
          src={`/assets/penis-and-testicles/LABELS-${kebabPart}.png`}
          alt={kebabPart}
        />
      </button>
    )
  }

  return (
    <div className='PenisExternal'>
      <img className="background" src="/assets/penis-and-testicles/BACKGROUND-M-External.jpg" alt="penis and testicles" />
      {clickableImage("scrotum", "scrotum")}
      {clickableImage("foreskin", "foreskin")}
      {clickableImage("penis", "penis")}
      {clickableImage("urethral-opening", "urethralOpening")}
    </div>
  )

}

export default PenisExternal