import React from 'react'
import './PenisInternal.scss'

const PenisInternal = ({selected, clickHandler}) => {

  const clickableImage = (kebabPart, camelPart) => {
    return (
      <button onClick={()=>clickHandler(camelPart)} >
        <img
          className={`${kebabPart} label ${!selected || selected === camelPart}`}
          src={`/assets/penis-and-testicles/LABEL-${kebabPart}.png`}
          alt={kebabPart}
        />
      </button>
    )
  }

  return (
    <div className='PenisInternal'>
      <img className="background" src="/assets/penis-and-testicles/BACKGROUND-M-Internal.jpg" alt="penis and testicles" />
      {clickableImage("bladder", "bladder")}
      {clickableImage("urethra", "urethra")}
      {clickableImage("seminal-vesicle", "seminalVesicle")}
      {clickableImage("prostate-gland", "prostateGland")}
      {clickableImage("vas-deferens", "vasDeferens")}
      {clickableImage("epididymis", "epididymis")}
      {clickableImage("testicle", "testicle")}
    </div>
  )

}

export default PenisInternal